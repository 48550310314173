<template>
    <v-container class="py-0">
        <div class="confidential mx-auto">
            <div class="confidential__title">
                NDA Agreement
            </div>
            <div class="divider my-3"></div>
            <div class="confidential__text black--text">
                <h3 class="black--text mb-6">GENERAL</h3>

                <p class="mb-6">
                    A Contract will be deemed valid upon acceptance of a project by a 3D designer. All listed terms and
                    conditions will apply to all Projects. <br>
                    Unco leaves a right to terminate a project with a 3D designer if the quality was deemed unacceptable
                    at any time without compensation. <br>
                    Failure to submit work before deadlines can be viewed as possible termination of a project. <br>
                    A 3D designer needs to follow all visual and technical guidance. <br>
                    Any financial or other damage caused by 3D designers disclosure of Intellectual Property, will be
                    mitigated by 3D designers.
                </p>

                <h3 class="black--text mb-6">INTELLECTUAL PROPERTY RIGHTS</h3>

                <p class="mb-6">
                    Any information obtained from Unco website is deemed private and confidential. This includes source
                    code, databases, functionality, software, website designs,product designs, product sketches, audio,
                    video, text, photographs, graphics on the Site (regardless of format) and media created through 3D
                    software (3ds max, Blender, Maya, Zbrush, etc) (collectively, the "Content") and the trademarks,
                    service marks, and logos.
                    <br>
                    All author’s uploaded Content is deemed their Intellectual Property (IP). <br>
                    All uploaded Content by 3D designer will be transferred to Unco as its owner.
                </p>

                <h3 class="black--text mb-6">PROHIBITED ACTIVITIES</h3>

                <p class="mb-6">
                    You may not access or use Unco website for any purpose other than that for which we make it available.
                    Unco website may not be used in connection with any commercial endeavors except those that are
                    specifically endorsed or approved by us. <br>
                    As a 3D designer of the Site, you agree not to:
                </p>
                <ul class="mb-6">
                    <li>Share privately or publicly any Content obtained from the Site</li>
                    <li>Distribute or sell any of the Intellectual property obtained on Unco website.</li>
                    <li>Authorize anyone else to obtain and share data from your account.</li>
                    <li>
                        Systematically retrieve data or other content from the Site to create or compile, directly or
                        indirectly, a collection, compilation, database, or directory without written permission from
                        us.
                    </li>
                    <li>
                        Use any information obtained from the Site in order to harass, abuse, or harm another person.
                    </li>
                    <li>
                        Engage in any automated use of the system, such as using scripts to send comments or messages,
                        or using any data mining, robots, or similar data gathering and extraction tools.
                    </li>
                    <li>Delete the copyright or other proprietary rights notice from any Content.</li>
                    <li>
                        Attempt to bypass any measures of the Site designed to prevent or restrict access to the Site,
                        or any portion of the Site.
                    </li>
                    <li>Attempt to impersonate another user or person or use the username of another user.</li>
                </ul>


                <p>There is no time limitation for prohibited activities to be lifted.</p>
            </div>
            <div class="mt-4 d-flex justify-end">
                <v-btn
                    v-if="isDesigner && !profile.ndaAgreementAccepted"
                    class="new-project-btn px-4"
                    color="#9FF4A9"
                    depressed
                    rounded
                    :loading="loading"
                    @click="acceptAgreement"
                    height="36">
                    Agree and Continue to the Site
                </v-btn>
            </div>
        </div>
    </v-container>
</template>

<script>
    import { userApi } from "@/api";
    import { mapGetters } from "vuex";

    export default {
        name: "confidential",
        data() {
            return {
                loading: false
            }
        },
        computed: {
            ...mapGetters([
                "isDesigner",
                "profile"
            ])
        },
        methods: {
            async acceptAgreement() {
                this.loading = true
                try {
                    await userApi.acceptAgreement()
                    this.profile.ndaAgreementAccepted = true
                    await this.$router.push({ name: "dashboard" })
                } catch (e) {
                    console.error(e)
                } finally {
                    this.loading = false
                }
            }
        }
    }
</script>

<style scoped lang="scss">
.divider {
    height: 1px;
    background: #E7E7E7;
}

.confidential {
    padding: 146px 0 160px 0;
    max-width: 789px;

    &__title {
        font-size: 24px;
        color: #464646;
    }

    &__text {
        font-size: 15px;
        line-height: 19px;
        color: #000000;
        max-height: 552px;
        overflow-y: auto;

        h3{
            font-size: 15px !important;
        }
    }
}
</style>
